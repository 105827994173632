import React from "react";
import logo from "../assets/logo3.png";
import classes from "./Header.module.css";
import Menu from "./Menu";
import Search from "./Search";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="container mx-auto">
      <div className={classes.header}>
        <div className='grid grid-cols-12 grid-rows-2'>
          <div className='col-start-1 col-span-2 row-span-2 '>
            <Link to="/home">
              <div className={classes.logo}>
                <img src={logo} alt="text" />
                <div className={classes.title}>Pheonix eMart</div>
              </div>
            </Link>
          </div>
          <div className='col-start-3 col-span-10'>
            <Search />
          </div>

          <div className='col-start-3 col-span-10'>
            <Menu />
          </div>

        </div>

        {/* <Search /> */}



        {/* <Menu /> */}
      </div>

      <br></br>
    </div>
  );
};
export default Header;
