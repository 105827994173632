import React from "react";

const Search = () => {
  return (
    <div className="top-4 flex justify-center my-6">
      <div
        className="flex border border-purple-200 rounded"
        style={{
          width: "50%",
         }}
      >
        <input
          type="text"
          className="block px-4 py-2 bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
          placeholder="Search for Products"
          style={{
            width: "100%",
          }}
        ></input>
      </div>
    </div>
  );
};

export default Search;
