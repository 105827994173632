import React from "react";
import classes from "./Card.module.css";

const Cardimage = (props) => {
  const isImageurl = props.image;

  if (isImageurl) {
    return (
    <img className={classes.styleImage} src={props.image} alt="Card"></img>
    );
  }
  return null;
};

const Cardcontent = (props) => {
  return (
    <div className={classes.styleCardContent}>
      <p className={classes.styleCardTitle}>{props.data.title}</p>
      <p className={classes.styleSellingpriceLabel}>{props.data.price}</p>
      <p className={classes.styleMrpLabel}>{props.data.mrp}</p>
      <label className={classes.styleOfferLabel}>{props.data.off}</label>
    </div>
  );
};

const Card = (props) => {
  return (
    <div className={classes.styleCard}>
      <div className={classes.styleimagediv}>
        <Cardimage image={props.data.image} />
      </div>

      <Cardcontent data={props.data} />

      <div className={classes.action}>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full w-full ">
          BUY NOW
        </button>
      </div>
    </div>
  );
};
export default Card;
