import React from "react";
import MenuSidebarItems from "./MenuSidebarItems";
import classes from "./Menu.module.css";

const Menu = () => {
  return (
   
      <div
        className="hidden xl:flex justify-center"
          >
        <MenuSidebarItems isClassname={classes.menu}
        // onCategorySelect=''
        isMenu="true" />
      </div>
    
  );
};

export default Menu;
