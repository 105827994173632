import React from "react";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import defaultValue from "../common/config";
import axios from "axios";
import { useState, useEffect } from "react";
import LoadSpinner from '../components/LoadSpinner';
import classes from '../pages/Product.module.css';
import NumberFormat from 'react-number-format';


const Productpage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const location = useLocation();
    const setvalue = location.state
    const getUrl = `${defaultValue.baseUrl + setvalue?.stateParams}`

    const getRating = () => {
        
        let td = [];
        for (let i = 1; i <= productData?.review ; i++) {
            td.push(<label key={i}>⭐</label>);
        }
        return td;
    }

    useEffect(() => {
        const getData = async () => {
            const response = await axios.get(getUrl);
            try {
                setData(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
       getData();
    //    console.log('product response'+ response)

    //               try {
    //             setData(response.data);
    //         } catch (err) {
    //             setError(err.message);
    //         } finally {
    //             setLoading(false);
    //         }

    }, [getUrl]);

    const productData = data.productByID;


    return (

        <div className='container mx-auto'>
            <Header />

            <div className='grid grid-cols-3'>
                {productData ?
                    <div className={classes.productdiv}>
                        <img className={classes.productimg} src={productData.image} alt=''></img>
                    </div>
                    : ''}
                <div className='col-span-2 mx-6 my-6 '>
                    <div className='font-black text-2xl my-2'>{productData?.title} </div>
                    <div>
                        {/* {console.log(productData?.review)} */}
                        {/* {(() => {
                            let td = [];
                            for (let i = 1; i <= productData?.review ; i++) {
                                td.push(<label key={i}>⭐</label>);
                            }
                            return td;
                        })()} */}
                        {getRating()}

                    </div>
                    <div >
                        <p className={classes.productPrice}>
                            <NumberFormat displayType={'text'} thousandSeparator={true} prefix={'₹ '} value={productData?.price} />
                        </p> </div>
                    <div >
                        <p >
                            <NumberFormat displayType={'text'} className={classes.productMrp} thousandSeparator={true} prefix={'₹ '} value={productData?.mrp} />
                        </p></div>
                    <div className={classes.productoffdiv}>
                        <label className={classes.productOffer}>
                            {productData?.off}
                        </label></div>
                    <div className={classes.productDescrip}>
                        <b>Description:</b>
                        <div>
                            {productData?.description}
                        </div>
                        <div className='my-10'>
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                Buy Now
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            {loading && (
                <div >
                    <LoadSpinner />
                </div>
            )}
            {error && <div>{`There is a problem fetching the data - ${error}`} </div>}
        </div>
    )
}
export default Productpage;