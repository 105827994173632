import Carditems from "../components/Carditems";
import MenuSidebarItems from "../components/MenuSidebarItems";
import classes from "./Homepage.module.css";
import { useSelector } from "react-redux";

//import { useState } from "react";

const Homepage = (props) => {
  // const [category, setCategory] = useState(null);
  // const getItembycategory = (data) => {
  //   setCategory(data);
  // };

  const categoryName = useSelector(state => state.category.categoryName);

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-4 auto-cols-max">
        <div className="w-full justify-center">

        {/* <MenuSidebarItems
            onCategorySelect={getItembycategory}
            isClassname={classes.sidebar}
            isMenu="false"
          /> */}
          <MenuSidebarItems
            isClassname={classes.sidebar}
            isMenu="false"
          />
        </div>
        <div className="col-span-3">
          <div className={classes.itemTitle}>{categoryName}</div>
          <div className="grid xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 auto-cols-max">
            <Carditems />
            {/* <Carditems category={category?.url} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Homepage;
