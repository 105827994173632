import React from "react";
import classes from "./LoadSpinner.module.css";

export default function LoadSpinner() {
  return (
    <div className={classes.spinner}>
      <div className={classes.loadingspinner}></div>
    </div>
  );
}
