import React from "react";
import { Route, Routes } from "react-router-dom";
import Productpage from "./pages/Productpage";
import Layout from "./components/Layout";

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<Layout />} />
      <Route path="/product"  element={<Productpage />} />
      <Route path="/home" element={<Layout />} />
    </Routes>
  );
}

export default App;
