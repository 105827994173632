import { createSlice, configureStore } from '@reduxjs/toolkit';


const initialState = {category: null, categoryName: null}


const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        getCategory(state, action) {
            state.category = action.payload.url;
            state.categoryName= action.payload.name;
        },
    }
});

const store = configureStore({
    reducer: { category: categorySlice.reducer }
})
export const categoryAction = categorySlice.actions
export default store;