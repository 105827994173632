import React from "react";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { categoryAction } from "../store/categorySlice";


const sidelinks = [
  { url: "mobileaccessories", name: "Mobiles & Accessories" },
  { url: "kitchenappliances", name: "Kitchen Appliances" },
  { url: "homeappliances", name: "Home Appliances" },
  { url: "computertablets", name: "Computers & Tablets" },
];

const MenuSidebarItems = (props) => {

  const dispatch = useDispatch();
  

  const categoryHandler = ({url,name}) => {
    dispatch(categoryAction.getCategory({url, name}))
    }

  return (
    <>
      <div className={props.isClassname}>
        <ul>
          {props.isMenu === "false" && <li>Categories</li>}
          <li onClick={() => categoryHandler({ url: "", name: "All Category"})}>
            <Link to={props.isMenu === "false" ? props.isMenu === "true" ? "#" : "/"  :"/"}>
              All
            </Link>
          </li>
          {sidelinks.map(({ url, name, index }) => (
            <li key={url} onClick={() => categoryHandler({url,name})}>
              
                {/* <li key={url} onClick={() => props.onCategorySelect({ url, name })}></li> */}
              <Link key={url} to= 
              {props.isMenu === "false" ? props.isMenu === "true" ? "#" : "/"  :"/"}>
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
export default MenuSidebarItems;
