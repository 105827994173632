import { useState, useEffect } from "react";
import LoadSpinner from "../components/LoadSpinner";
import Card from "../components/Card";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import config from "../common/config";

import getData from "../services/getData";

const Carditems = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // old getUrl using props
  // const getUrl = props.category
  // ? `${config.baseUrl}category/${props.category}`
  // : `${config.baseUrl}`;

  const category = useSelector((state) => state.category.category);

  
  const getUrl = category
    ? `${config.baseUrl}category/${category}`
    : `${config.baseUrl}`;

  useEffect(() => {
    getData(getUrl)
    .then((res => setData(res.data)))
      .catch((err) => setError(err.message))
      .finally(setLoading(false));
  }, [getUrl]);


console.log(data)
  return (
    <>
      {data &&
        (data.ListOfProducts || []).map((cardCont) => {
          return (
            <div key={cardCont.productId} className="p-3">
              <Link
                to={{ pathname: "/product" }}
                state={{ stateParams: cardCont.productId }}
                //target="_blank"
              >
                <Card data={cardCont} />
              </Link>
            </div>
          );
        })}
      {loading && (<div> <LoadSpinner /> </div>)}
      {error && <div>{`There is a problem fetching the data - ${error}`} </div>}
    </>
  );
};
export default Carditems;
